import { useAutoMotorStartPageDigitalAdvertising } from '@bbx/common/digital-advertising/hooks/useAutoMotorStartPageDigitalAdvertising'
import { StoryblokAutoMotorStartpage } from '@bbx/common/types/storyblok/StoryblokStartpage'
import { AutoMotorAdTypeCounters } from '@bbx/search-journey/common/api/searchApiClient'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import {
    fakeCarLinkVomitDataFirstRow,
    fakeCarLinkVomitDataSecondRow,
} from '@bbx/search-journey/sub-domains/search/api/static/link-vomit-start-page-auto'
import { StartPageLinkVomitWidget } from '@bbx/search-journey/sub-domains/search/components/common/common/LinkVomitWidget/LinkVomitWidget'
import { StoryblokDirectSearchEntryWidget } from '@bbx/search-journey/sub-domains/search/components/common/start-pages/ContentArea/StoryblokDirectSearchEntryWidget'
import { SearchBox } from '@bbx/search-journey/sub-domains/search/components/verticals/auto-motor/start-pages/SearchEntryArea/SearchBox'
import { SearchEntryArea } from '@bbx/search-journey/sub-domains/search/components/common/start-pages/SearchEntryArea/SearchEntryArea'
import { ISbStoryData } from '@storyblok/react'
import { Divider } from '@wh-components/core/Divider/Divider'
import React, { Fragment, FunctionComponent } from 'react'
import { AzaSparePartsRow } from './ContentArea/AzaSparePartsRow'
import { ContentArea } from './ContentArea/ContentArea'
import { MakeVomitWidget } from './ContentArea/MakeVomitWidget'
import carBackgroundSmall from '@bbx/static_hashed/img/motor/search-entry-area/automotor_start_auto_S.jpg'
import carBackgroundMediumLarge from '@bbx/static_hashed/img/motor/search-entry-area/automotor_start_auto.jpg'
import carBackgroundText from '@bbx/static_hashed/img/motor/search-entry-area/automotor_start_auto_text.png'
import { AutoMotorSearchEntryAreaTabs } from '@bbx/search-journey/sub-domains/search/components/verticals/auto-motor/start-pages/SearchEntryArea/AutoMotorSearchEntryAreaTabs'
import { StartPageTopAdsWidget } from '@bbx/search-journey/sub-domains/search/components/common/start-pages/ContentArea/StartPageTopAdsWidget'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'

export interface StartPageContainerProps {
    initialSearchResult: SearchResult
    initialCounters: AutoMotorAdTypeCounters
    story: ISbStoryData<StoryblokAutoMotorStartpage> | null
}

export const AutoMotorStartPageContainer: FunctionComponent<StartPageContainerProps> = ({
    initialSearchResult,
    initialCounters,
    story,
}) => {
    useAutoMotorStartPageDigitalAdvertising('motor')

    const fetchTopAdsLink = findContextLinkWithId('fetchTopAds', initialSearchResult.searchContextLinks)

    return (
        <Fragment>
            <SearchEntryArea
                searchBox={<SearchBox initialSearchResult={initialSearchResult} type="car" />}
                tabs={
                    <AutoMotorSearchEntryAreaTabs
                        initialCounters={initialCounters}
                        activeTab="car"
                        taggingData={initialSearchResult.taggingData}
                    />
                }
                backgroundSmall={carBackgroundSmall}
                backgroundMediumLarge={carBackgroundMediumLarge}
                backgroundText={carBackgroundText}
            />
            <ContentArea
                topContent={
                    <Fragment>
                        <StoryblokDirectSearchEntryWidget
                            story={story}
                            taggingData={initialSearchResult.taggingData}
                            taggingPrefix="MotorStartpage::QuickLink"
                        />
                        <AzaSparePartsRow azaUrl="/iad/auto-verkaufen" taggingData={initialSearchResult.taggingData} />
                        {fetchTopAdsLink && (
                            <StartPageTopAdsWidget
                                title="Top Fahrzeug-Anzeigen"
                                taggingData={initialSearchResult.taggingData}
                                taggingEvent="vertical_home_topad_click"
                                fetchTopAdsLink={fetchTopAdsLink}
                            />
                        )}
                        <Divider marginBottom="l" />
                        <MakeVomitWidget
                            taggingData={initialSearchResult.taggingData}
                            story={story}
                            taggingPrefix="MotorStartpage::PopularMakes"
                            testId="auto-motor"
                        />
                    </Fragment>
                }
                bottomContent={
                    <StartPageLinkVomitWidget
                        heading="Beliebte Suchen"
                        firstRow={fakeCarLinkVomitDataFirstRow}
                        secondRow={fakeCarLinkVomitDataSecondRow}
                    />
                }
            />
        </Fragment>
    )
}
