import { LinkGroup } from '@wh/common/chapter/types/SeoMetaData'

export const fakeCarLinkVomitDataFirstRow: LinkGroup[] = [
    {
        title: 'VW',
        links: [
            { name: 'VW Golf', link: '/iad/gebrauchtwagen/auto/vw-gebrauchtwagen/golf/' },
            { name: 'VW Passat', link: '/iad/gebrauchtwagen/auto/vw-gebrauchtwagen/passat/' },
            { name: 'VW Polo', link: '/iad/gebrauchtwagen/auto/vw-gebrauchtwagen/polo/' },
            { name: 'VW Sharan', link: '/iad/gebrauchtwagen/auto/vw-gebrauchtwagen/sharan/' },
            { name: 'VW Tiguan', link: '/iad/gebrauchtwagen/auto/vw-gebrauchtwagen/tiguan/' },
            { name: 'VW Touran', link: '/iad/gebrauchtwagen/auto/vw-gebrauchtwagen/touran/' },
        ],
    },
    {
        title: 'Audi',
        links: [
            { name: 'Audi A3', link: '/iad/gebrauchtwagen/auto/audi-gebrauchtwagen/a3/' },
            { name: 'Audi A4', link: '/iad/gebrauchtwagen/auto/audi-gebrauchtwagen/a4/' },
            { name: 'Audi A5', link: '/iad/gebrauchtwagen/auto/audi-gebrauchtwagen/a5/' },
            { name: 'Audi A6', link: '/iad/gebrauchtwagen/auto/audi-gebrauchtwagen/a6/' },
            { name: 'Audi Q3', link: '/iad/gebrauchtwagen/auto/audi-gebrauchtwagen/q3/' },
            { name: 'Audi Q5', link: '/iad/gebrauchtwagen/auto/audi-gebrauchtwagen/q5/' },
        ],
    },
    {
        title: 'BMW',
        links: [
            { name: 'BMW 1er', link: '/iad/gebrauchtwagen/auto/bmw-gebrauchtwagen/1er-reihe/' },
            { name: 'BMW 3er', link: '/iad/gebrauchtwagen/auto/bmw-gebrauchtwagen/3er-reihe/' },
            { name: 'BMW 5er', link: '/iad/gebrauchtwagen/auto/bmw-gebrauchtwagen/5er-reihe/' },
            { name: 'BMW X1', link: '/iad/gebrauchtwagen/auto/bmw-gebrauchtwagen/x1/' },
            { name: 'BMW X3', link: '/iad/gebrauchtwagen/auto/bmw-gebrauchtwagen/x3/' },
            { name: 'BMW X5', link: '/iad/gebrauchtwagen/auto/bmw-gebrauchtwagen/x5/' },
        ],
    },
    {
        title: 'Mercedes-Benz',
        links: [
            { name: 'Mercedes-Benz A-Klasse', link: '/iad/gebrauchtwagen/auto/mercedes-gebrauchtwagen/a-klasse/' },
            { name: 'Mercedes-Benz B-Klasse', link: '/iad/gebrauchtwagen/auto/mercedes-gebrauchtwagen/b-klasse/' },
            { name: 'Mercedes-Benz C-Klasse', link: '/iad/gebrauchtwagen/auto/mercedes-gebrauchtwagen/c-klasse/' },
            { name: 'Mercedes-Benz CLA-Klasse', link: '/iad/gebrauchtwagen/auto/mercedes-gebrauchtwagen/cla-klasse/' },
            { name: 'Mercedes-Benz E-Klasse', link: '/iad/gebrauchtwagen/auto/mercedes-gebrauchtwagen/e-klasse/' },
            { name: 'Mercedes-Benz GLC-Klasse', link: '/iad/gebrauchtwagen/auto/mercedes-gebrauchtwagen/glc-klasse/' },
        ],
    },
]
export const fakeCarLinkVomitDataSecondRow: LinkGroup[] = [
    {
        title: 'Ford',
        links: [
            { name: 'Ford C-MAX', link: '/iad/gebrauchtwagen/auto/ford-gebrauchtwagen/c-max/' },
            { name: 'Ford Fiesta', link: '/iad/gebrauchtwagen/auto/ford-gebrauchtwagen/fiesta/' },
            { name: 'Ford Focus', link: '/iad/gebrauchtwagen/auto/ford-gebrauchtwagen/focus/' },
            { name: 'Ford Galaxy', link: '/iad/gebrauchtwagen/auto/ford-gebrauchtwagen/galaxy/' },
            { name: 'Ford Kuga', link: '/iad/gebrauchtwagen/auto/ford-gebrauchtwagen/kuga/' },
            { name: 'Ford Mondeo', link: '/iad/gebrauchtwagen/auto/ford-gebrauchtwagen/mondeo/' },
        ],
    },
    {
        title: 'Opel',
        links: [
            { name: 'Opel Astra', link: '/iad/gebrauchtwagen/auto/opel-gebrauchtwagen/astra/' },
            { name: 'Opel Corsa', link: '/iad/gebrauchtwagen/auto/opel-gebrauchtwagen/corsa/' },
            { name: 'Opel Crossland', link: '/iad/gebrauchtwagen/auto/opel-gebrauchtwagen/crossland/' },
            { name: 'Opel Insignia', link: '/iad/gebrauchtwagen/auto/opel-gebrauchtwagen/insignia/' },
            { name: 'Opel Mokka', link: '/iad/gebrauchtwagen/auto/opel-gebrauchtwagen/mokka/' },
            { name: 'Opel Zafira', link: '/iad/gebrauchtwagen/auto/opel-gebrauchtwagen/zafira/' },
        ],
    },
    {
        title: 'Regional',
        links: [
            { name: 'Gebrauchtwagen in Wien', link: '/iad/gebrauchtwagen/auto/l/wien' },
            { name: 'Gebrauchtwagen in Salzburg', link: '/iad/gebrauchtwagen/auto/l/salzburg' },
            { name: 'Gebrauchtwagen in Graz', link: '/iad/gebrauchtwagen/auto/l/graz' },
            { name: 'Gebrauchtwagen in Klagenfurt', link: '/iad/gebrauchtwagen/auto/l/klagenfurt' },
            { name: 'Gebrauchtwagen in Linz', link: '/iad/gebrauchtwagen/auto/l/linz' },
            { name: 'Gebrauchtwagen in Innsbruck', link: '/iad/gebrauchtwagen/auto/l/innsbruck' },
        ],
    },
    {
        title: 'Fahrzeug verkaufen',
        links: [
            { name: 'Gebrauchtwagen GRATIS inserieren', link: '/iad/auto-verkaufen' },
            { name: 'Motorrad GRATIS inserieren', link: '/iad/motorrad-verkaufen' },
            { name: 'LKW inserieren', link: '/iad/lkw-nutzfahrzeug-verkaufen' },
            { name: 'Wohnwagen inserieren', link: '/iad/wohnwagen-wohnmobil-verkaufen' },
        ],
    },
]
