import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { NavigatorId } from '@bbx/search-journey/common/Navigators'

export const removeAdsAndUnneededNavigators = (searchResult: SearchResult, includedNavigators: NavigatorId[]): SearchResult => {
    const filteredGroups = searchResult.navigatorGroups.map((group) => {
        const filteredNavigators = group.navigatorList.filter((navigator) => includedNavigators.includes(navigator.id))
        return { ...group, navigatorList: filteredNavigators }
    })

    return { ...searchResult, advertSummaryList: { advertSummary: [] }, navigatorGroups: filteredGroups }
}
